<template>
  <svg
      fill="none"
      height="17"
      viewBox="0 0 18 17"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        :fill="fillColor"
        clip-rule="evenodd"
        d="M9.03771 1.05034e-07C10.5682 -6.48197e-06 11.7678 -1.17347e-05 12.7196 0.103107C13.6909 0.208339 14.4779 0.426937 15.1498 0.915127C15.5566 1.21067 15.9143 1.56841 16.2099 1.9752C16.6981 2.64713 16.9167 3.43412 17.0219 4.4054C17.125 5.35716 17.125 6.55687 17.125 8.08727V8.16279C17.125 9.6932 17.125 10.8928 17.0219 11.8446C16.9167 12.8159 16.6981 13.6029 16.2099 14.2748C15.9143 14.6816 15.5566 15.0393 15.1498 15.3349C14.4779 15.8231 13.6909 16.0417 12.7196 16.1469C11.7678 16.25 10.5681 16.25 9.03773 16.25H8.96221C7.4318 16.25 6.23216 16.25 5.2804 16.1469C4.30912 16.0417 3.52213 15.8231 2.8502 15.3349C2.44341 15.0393 2.08567 14.6816 1.79013 14.2748C1.30194 13.6029 1.08334 12.8159 0.978107 11.8446C0.874988 10.8928 0.874994 9.69316 0.875 8.16271V8.08729C0.874994 6.55685 0.874988 5.35717 0.978107 4.4054C1.08334 3.43412 1.30194 2.64713 1.79013 1.9752C2.08568 1.56841 2.44341 1.21068 2.8502 0.915127C3.52213 0.426937 4.30912 0.208339 5.2804 0.103107C6.23218 -1.17347e-05 7.43184 -6.48197e-06 8.96228 1.05034e-07H9.03771ZM13.3334 7.25C13.6786 7.25 13.9584 6.97018 13.9584 6.625C13.9584 6.27982 13.6786 6 13.3334 6H6.37508C6.0299 6 5.75008 6.27982 5.75008 6.625C5.75008 6.97018 6.0299 7.25 6.37508 7.25H13.3334ZM13.3335 10.625C13.6787 10.625 13.9585 10.3452 13.9585 9.99999C13.9585 9.65482 13.6787 9.37499 13.3335 9.37499L6.37508 9.33333C6.0299 9.33333 5.75008 9.61316 5.75008 9.95833C5.75008 10.3035 6.0299 10.5833 6.37508 10.5833L13.3335 10.625ZM5.25 6.625C5.25 6.27982 4.97018 6 4.625 6C4.27982 6 4 6.27982 4 6.625C4 6.97018 4.27982 7.25 4.625 7.25C4.97018 7.25 5.25 6.97018 5.25 6.625ZM5.25008 9.99999C5.25008 9.65482 4.97026 9.37499 4.62508 9.37499L4.625 9.33333C4.27982 9.33333 4 9.61316 4 9.95833C4 10.3035 4.27982 10.5833 4.625 10.5833L4.62508 10.625C4.97026 10.625 5.25008 10.3452 5.25008 9.99999Z"
        fill-rule="evenodd"
    />
    <defs>
      <linearGradient
          id="paint0_linear_141_1170"
          gradientUnits="userSpaceOnUse"
          x1="0.46875"
          x2="17.9906"
          y1="-1.35416"
          y2="2.36611"
      >
        <stop offset="1" stop-color="#58E2B0" />
        <stop offset="1" stop-color="#3EB0D3" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts" setup>
import {computed} from 'vue'

const props = defineProps<{
  active: boolean
}>()

const fillColor = computed(() => {
  if (props.active) {
    return 'url(#paint0_linear_141_1170)'
  } else return '#8E9EBD'
})
</script>

<style scoped>
svg {
  margin-top: 2px;
  margin-right: 1rem;
}
</style>
